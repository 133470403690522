<template>
  <div class="register">
    <b-container fluid id="form">
      <b-row>
        <b-col cols="12">
          <b-form @submit="onSubmit">
            <b-row>
              <b-col>
                <b-form-group
                  id="input-subject"
                  label="Betreff:"
                  label-for="input-subject"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-select
                      id="subject"
                      v-model="form.subject"
                      :options="options.subject"
                      required
                      :disabled="form_disabled_subject"
                      data-validation-text="Bitte wählen Sie einen Eintrag in der Liste."
                    ></b-form-select>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-alert variant="warning" :show="msg_other_show">
                  <h4><span v-html="msg_other_text"></span></h4>
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-first-name"
                  label="Vorname:"
                  label-for="input-first-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                  data-validation-text="Bitte ausfüllen!!111"
                >
                  <b-col>
                    <b-form-input
                      id="first-name"
                      v-model="form.first_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-last-name"
                  label="Nachname:"
                  label-for="input-last-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col>
                    <b-form-input
                      id="last-name"
                      v-model="form.last_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-phone"
                  label="Telefon:"
                  label-for="input-phone"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="phone"
                      v-model="form.phone"
                      type="tel"
                      pattern="[0-9].+"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte geben Sie eine gültige Telefonnummer im folgenden Format: '02514920' ein"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-mmail"
                  label="Ihre muenster.de E-Mail-Adresse:"
                  label-for="input-mail"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="mail"
                        v-model="form.muenster_mail"
                        required
                        :disabled="form_disabled"
                        data-validation-text="Bitte füllen Sie dieses Feld aus"
                      ></b-form-input>
                      <span class="input-group-text" id="basic-addon2">@muenster.de</span>
                    </div>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-amail"
                  label="Ihre alternative E-Mail-Adresse (optional, z.B.  @web,@gmx,@gmail,@t-online, ...)"
                  label-for="input-mail"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="mail"
                        v-model="form.alternative_mail"
                        :disabled="form_disabled"
                        data-validation-text="Bitte füllen Sie dieses Feld aus"
                      ></b-form-input>
                    </div>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-alert show variant="warning" v-model="hint_show">{{ hint_text }}</b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-message"
                  label="Ihre Nachricht:"
                  label-for="input-message"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-textarea
                      id="message"
                      v-model="form.message"
                      type="tel"
                      required
                      :disabled="form_disabled"
                      rows="12"
                      data-validation-text="Bitte geben Sie eine Nachricht ein."
                    ></b-form-textarea>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="this.form.captcha_id != ''">
              <b-col>
                <img :src="get_image_captcha_url()" />
              </b-col>
              <b-col>
                <audio
                  id="audio_with_controls"
                  controls
                  :src="get_audio_captcha_url()"
                  type="audio/mp3"
                >
                  Ihr Browser kann dieses Tondokument nicht wiedergeben.<br />
                </audio>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <img src="" />
                <b-form-group
                  id="input-captcha"
                  label="Captcha:"
                  label-for="input-captcha"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="phone"
                      v-model="form.captcha_solution"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-alert v-model="msg_show" v-bind:variant="msg_type">
                  <h4>{{ msg_text }}</h4>
                </b-alert>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <b-button type="submit" variant="primary" :disabled="form_disabled"
                  >Antrag abschicken</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Form",
  data() {
    return {
      form_disabled: false,
      msg_type: "sucess",
      msg_text: "",
      msg_show: false,
      msg_other_text: "",
      hint_show: false,
      hint_text: "",
      options: {
        subject: [
          "Hilfe zu E-Mail-Programm (Outlook, Thunderbird, ...), Smartphone oder Tablet",
          "Kennwort vergessen",
          "Konto löschen",
          "Alias beantragen",
          "Probleme mit der Webmail-Nutzung",
        ],
      },
      form: {
        first_name: "",
        last_name: "",
        phone: "",
        alternative_mail: "",
        muenster_mail: "",
        subject: "",
        message: "",
        captcha_id: "",
        captcha_solution: "",
      },
    };
  },
  mounted() {
    this.get_captcha_id();
    var elements = document.getElementsByTagName("INPUT");
    for (var i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function(e) {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
          e.target.setCustomValidity(e.target.dataset.validationText);
        }
      };
      elements[i].oninput = function(e) {
        e.target.setCustomValidity("");
      };
    }
  },
  watch: {
    "form.subject": function() {
      if (
        this.form.subject == "Kennwort vergessen" ||
        this.form.subject == "Konto löschen" ||
        this.form.subject == "Alias beantragen" ||
        this.form.subject ==
          "Hilfe zu E-Mail-Programm (Outlook, Thunderbird, ...), Smartphone oder Tablet"
      ) {
        if (this.form.subject == "Konto löschen") {
          this.msg_other_text =
            'Um ihr Konto zu löschen, schicken Sie uns bitte eine E-Mail <b>von dem betreffenden Konto</b> mit ihrem Wunsch an <a href="mailto:muenster-mail@am-gmbh.de?subject=Antrag Konto l&#246;schen&body=Bitte l&#246;schen Sie mein E-Mail-Konto ...@muenster.de">muenster-mail@am-gmbh.de</a>';
        }
        if (this.form.subject == "Kennwort vergessen") {
          this.msg_other_text =
            'Um ein neues Kennwort zu beantragen, verwenden Sie bitte das Formular unter <a href="https://www.muenster.de/email_passwort.html">www.muenster.de/email_passwort.html</a>.';
        }
        if (this.form.subject == "Alias beantragen") {
          this.msg_other_text =
            'Um einen Alias zu beantragen, verwenden Sie bitte das Formular unter <a href="https://www.muenster.de/email_alias.html">www.muenster.de/email_alias.html</a>.';
        }
        if (this.form.subject == "Hilfe zu E-Mail-Programm (Outlook, Thunderbird, ...), Smartphone oder Tablet") {
          this.msg_other_text =
            'Leider übernimmt die Stadt Münster keine Supportleistungen für Endgeräte. Bei Problemen können Sie sich an den Verein Bürgernetz wenden: telefonisch unter 0251/54535 (in der Regel montags bis donnerstags von 9 bis 15 Uhr) oder per Mail: <a href="mailto:mail-service@muenster.org">mail-service@muenster.org</a>';
        }
        this.msg_other_show = true;
        this.form_disabled = true;
      } else {
        this.msg_other_show = false;
        this.form_disabled = false;
      }
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      axios
        .post("/api/save", JSON.stringify(this.form))
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.msg_type = "success";
          this.msg_text = "Daten erfolgreich übermittelt. Wir kümmern uns zeitnah um ihr Anliegen.";
          this.msg_show = true;
          this.form_disabled = true;
          this.form_disabled_subject = true;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          if (error != null) {
            this.msg_type = "warning";
            this.msg_show = true;
            this.msg_text = error.response.data;
            this.get_captcha_id();
          }
          console.log(error);
        });
    },
    get_captcha_id() {
      axios.get("/api/captcha").then((response) => (this.form.captcha_id = response.data));
    },
    get_image_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".png";
    },
    get_audio_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".wav";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
a {
  color: #005b79;
  text-decoration: underline;
}
.form {
  margin: 40px;
}
.info p {
  text-align: left;
}

.modal-dialog {
  max-width: 80%;
}

.btn-primary {
  background-color: #005b79;
  border-color: #005b79;
}
</style>
